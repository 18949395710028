import Init from './base/init.js';
import Root from './base/root.js';
import Fonts from './base/fonts.js';
import Splitter from './builders/splitter.js';
import CssBuilder from './builders/css.js';
import StringBuilder from "./builders/string.js";
import BreakpointBuilder from "./builders/breakpoint.js";
import PatternBuilder from "./builders/pattern.js";
import Tiny from "./builders/tiny.js";

/**
 * Core functionality for compiling CSS using Mojo Core.
 * @class
 */
class MojoCore {
  version = "0.2.0-rc.13";

  /**
   * Initializes the MojoCore instance.
   * @param {Object} args - Arguments for initialization.
   * @returns {MojoCore} - The initialized MojoCore instance.
   */
  init(args = {}) {
    this.args = args;

    if (this.args.isExtend === undefined)
      this.args.isExtend = false;

    if (this.args.theme === "default-theme")
      this.args.theme = "default";

    if (this.args.theme === "i-default-theme")
      this.args.theme = "i-default";

    return this;
  }

  /**
   * Sets the configuration for MojoCore.
   * @param {Object} config - The configuration object.
   * @returns {MojoCore} - The MojoCore instance with the configuration set.
   */
  setConfig(config) {
    this.config = config;
    this.initStyles = new Init(config).getCss();
    this.fontsStyles = new Fonts(config).getCss();

    if (!this.usedColors) {
      this.usedColors = ["body", "invert"];
    }

    if (this.userUtilities === undefined)
      this.userUtilities = {};


    return this;
  }

  /**
   * Adds a utility to MojoCore.
   * @param {Object} obj - The utility object to add.
   */
  addUtility(obj = {}) {
    let body = '';
    if (obj.body !== undefined) {
      body = obj.body;
      body = body.replace(/\\n/g, " ");
      body = body.replace(/\\t/g, " ");

      while (body.endsWith(" ")) {
        body = body.substring(0, body.length - 1);
      }
    }
    if (obj.pattern) {
      if (body.length > 0 && !body.endsWith(";"))
        body += ";"

      body += this.getPatternStyles(
        {
          0: obj.pattern
        }
      )
    }

    if (obj.name && obj.props)
      this.userUtilities[obj.name] = {
        props: obj.props,
        isStatic: false
      }
    if (obj.name && body !== '')
      this.userUtilities[obj.name] = {
        props: body,
        isStatic: true
      }
  }

  /**
   * Retrieves the initial styles for MojoCore.
   * @returns {string} - The initial styles.
   */
  getInitStyles() {
    if (this.initStyles === undefined)
      return '';
    else
      return this.initStyles;
  }

  /**
   * Retrieves the root styles for MojoCore.
   * @returns {string} - The root styles.
   */
  getRootStyles() {
    if (this.fontsStyles === undefined)
      this.fontsStyles = new Fonts(this.config).getCss();
    return this.fontsStyles + new Root(this.config, this.usedColors).getCss();
  }

  /**
   * Adds a pseudo to MojoCore.
   * @param {string} name - The name of the pseudo-element.
   * @param {Object} obj - The style object for the pseudo-element.
   */
  addPseudo(name, obj) {
    if (StringBuilder.pseudos[name] === undefined) {
      StringBuilder.pseudos[name] = obj;
    }
  }

  /**
   * Retrieves the pseudo-elements configured in MojoCore.
   * @returns {string[]} - Array of pseudo-element names.
   */
  getPseudos() {
    return Object.keys(StringBuilder.pseudos);
  }

  /**
   * Retrieves pattern styles for MojoCore.
   * @param {Object} patterns - Object containing patterns to apply.
   * @returns {string} - The pattern styles.
   */
  getPatternStyles(patterns) {
    return new PatternBuilder({
      config: this.config,
      userUtilities: this.userUtilities,
      usedColors: this.usedColors,
      allPseudos: this.getPseudos(),
      patterns
    }).getStyles();
  }

  /**
   * Retrieves styles generated by MojoCore.
   * @returns {Object} - Object containing generated CSS and time taken.
   */
  getStyles() {
    let args = this.args;

    if (args.tiny) {
      args.tiny = new Tiny().parseTiny(args.tiny, this.config, this.getPseudos());
      args = {...args, ...args.tiny.args}
    }

    let CSS = "";
    const t1 = Date.now();

    if(args.pseudo && !Array.isArray(args.pseudo)){
      args.pseudo = [args.pseudo];
    }

    const params = {
      config: this.config,
      isExtend: args.isExtend,
      pseudo: args.pseudo,
      theme: args.theme,
      breakpoint: args.breakpoint,
      usedColors: this.usedColors,
      attribute: args.attribute,
      tiny: args.tiny,
    }
    let body = [];

    if (!args.isExtend) {
      params.element = args.element;
    } else {
      params.selector = args.selector;
    }

    for (let c of args.classes) {
      params.splittedClass = new Splitter(c, this.userUtilities, this.config);

      if (!args.isExtend) {
        CSS += new CssBuilder(params).create();
      } else {
        if (params.splittedClass.body === undefined)
          params.splittedClass.body = args.body;

        body.push(new CssBuilder({...params, pseudo: undefined}).create());
      }
    }

    if(body.length > 0){
      body = body.join(";")

      CSS = new StringBuilder({
        pseudo: args.pseudo,
        className: args.selector,
        theme: args.theme,
        isExtend: args.isExtend,
        body: body.toString().replace(/;/g, ";\n    "),
      }).getCss();

      if (args.breakpoint !== undefined && this.config.options.minify === false)
        CSS = CSS.replace(/\n/g, "\n    ");
    }

    CSS = new BreakpointBuilder({
      ...args,
      allPseudos: StringBuilder.pseudos,
      config: this.config
    }).insertBreakpoint(CSS)

    const t2 = Date.now();
    return {
      css: CSS,
      time: t2 - t1
    };
  }
}

const core = new MojoCore();
export default core;

